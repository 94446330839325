@import "~@theme/variables.scss";
.accountLoginWrapper {
  @media (min-width: #{$lg}) {
    max-width: 488px;
  }
}

.accountLoginSocial {
  margin-top: 40px;
  margin-bottom: 43px;
}

.accountLoginSocialItem {
  border-width: 1px;
  border-style: solid;
  border-color: $lightGrayDark;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;

  @media (min-width: #{$lg}) {
    flex-wrap: nowrap;
  }
}

.accountLoginSocialItemContent {
  display: flex;
  width: 100%;
  flex-grow: 1;

  @media (min-width: #{$lg}) {
    width: auto;
  }
}

.accountLoginSocialItemLogo {
  margin-right: 10px;
}

.accountLoginSocialItemInfo {
  flex-grow: 1;
}

.accountLoginSocialItemName {
  margin-bottom: 0;
}

.accountLoginSocialItemNote {
  font-size: 14px;
  color: $darkestGray;
}

.accountLoginSocialItemBtn {
  margin-top: 14px;
  width: 100%;
  
  @media (min-width: #{$lg}) {
    width: auto;
    margin-top: 0;
    margin-left: 10px;
  }
}
