@import "~@theme/variables.scss";
.accountBaseInputActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accountBaseInputActionsClose {
  color: $errorRed;
  padding: 0;

  :global {
    .icon {
      font-size: 20px;
    }
  }
}

.accountBaseInputViewProgress {
  margin-left: 12px;
}

.ActionsMobileFullWidth {
  width: 100%;
  @media (min-width: #{$lg}) {
    width: auto;
  }
}