@import "~@theme/variables.scss";
.accountBaseInputEdit {
  position: relative;
}

.accountBaseInputEditField {
  position: relative;
}

.accountBaseInputEditButtons {
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.accountBaseInputEditBtn {
  font-weight: 400;
  padding: 0;
}

.accountBaseInputEditClose {
  margin-left: 12px;
  color: $errorRed;
  padding: 0;

  .icon {
    font-size: 20px;
  }
}
