@import "~@theme/variables.scss";
.accountBaseInputView {
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $mediumGray;
  padding: 15px 12px 14px;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .icon {
    font-size: 20px;
    color: $successGreen;
    margin-right: 12px;
  }
}

.accountBaseInputViewValue {
  flex-grow: 1;
}

.accountBaseInputViewBtn {
  font-weight: 400;
  padding: 0;
}

.accountBaseInputViewProgress {
  margin-left: 12px;
}
